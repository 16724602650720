import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import { withIntl } from 'i18n'

import Layout from '../components/Layout'
import SEO from '../components/Layout/SEO'
import Hero from '../components/Hero'
import ContentBlock from '../components/ContentBlock'
import Architects from '../components/Architects'

const ArchitectsPage = ({ data, intl }) => {
  const { architects } = data.architects

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'architects' })}
        description={intl.formatMessage({ id: 'description.architects' })}
      />

      <Hero
        className="architects"
        title={intl.formatMessage({ id: 'architects.title' })}
        image={data.image}
        quote={{
          text: intl.formatMessage({ id: 'architects.quote' }),
          author: intl.formatMessage({ id: 'architects.quote.author' }),
        }}
      />

      <ContentBlock
        title={intl.formatMessage({ id: 'architects' })}
        className="mobile-only"
      >
        <div className="container">
          <p>{intl.formatMessage({ id: 'architects.intro.text.1' })}</p>
          <p>{intl.formatMessage({ id: 'architects.intro.text.2' })}</p>
          <p>{intl.formatMessage({ id: 'architects.intro.text.3' })}</p>
        </div>
      </ContentBlock>

      <ContentBlock
        title={intl.formatMessage({ id: 'architects' })}
        className="architects no-title-mobile"
      >
        <Architects architects={architects} />
      </ContentBlock>
    </Layout>
  )
}

export default withIntl(injectIntl(ArchitectsPage))

export const ArchitectsQuery = graphql`
  query ArchitectsQuery($locale: String) {
    image: file(relativePath: { eq: "architects-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    architects: architectsJson(locale: { eq: $locale }) {
      architects {
        title
        role
        image
        text
        slides
      }
    }
  }
`
