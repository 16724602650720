import React from 'react'
import PropTypes from 'prop-types'
import Parallax from 'react-rellax'

import Slider from '../Slider'

import './architects.scss'

const Architects = ({ architects }) => {
  return (
    <div className="architects-wrap">
      <ul>
        {architects.map((architect, index) => {
          return (
            <li key={`architect-${index}`}>
              <div className="container">
                <div className="row">
                  <div className="col-image">
                    <img src={architect.image} alt={architect.title} />
                  </div>
                  <div className="col-content">
                    <div className="inner-content">
                      <h3>{architect.title}</h3>
                      <div className="role">{architect.role}</div>

                      {architect.text.map((text, i) => (
                        <p key={`text-${index}-${i}`}>{text}</p>
                      ))}
                    </div>
                  </div>
                </div>

                {architect.slides && <Slider slides={architect.slides} />}
              </div>
              <Parallax
                speed={-2}
                percentage={0.3 * index}
                className="circle"
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Architects.propTypes = {
  architects: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Architects
